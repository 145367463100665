import React from "react";
import MainPagePresenter from "./MainPagePresenter";
import { Menu } from "nq-component";
import { getAllSchemasUseCase } from "../../usecases/schema/usecases";
import { getCurrentUserUseCase, signOutUseCase } from "../../usecases/user";
import { Routes, Route } from "react-router-dom";
import { OffCanvas } from "nq-component";
import CollectionListPage from "../collection-list/CollectionListPage";
import CollectionFormPage from "../collection-form/CollectionFormPage";
import BasePage from "../../base/BasePage";
import NotFoundPage from "../notfound";
import { Layout, Progress, LogoHolder } from "nq-component";
import MigrationPage from "../migration/MigrationPage";
import AccountPage from "../account/AccountPage";
import RoleFormPage from "../role-form/RoleFormPage";
import canRead from "../../canRead";
import withRouter from "../../withRouter";
import DashboardPage from "../dashboard/DashboardPage";
import RoleBiFormPage from "../role-bi-form/RoleBiFormPage";
import StoreTransaction from "../store-transaction/StoreTransactionPage";
import StoreTransactionPage from "../store-transaction/StoreTransactionPage";

class MainPage extends BasePage {
  constructor(props) {
    super(props);
    this.presenter = new MainPagePresenter(
      this,
      getCurrentUserUseCase(),
      signOutUseCase(),
      getAllSchemasUseCase()
    );
  }

  componentDidMount() {
    this.presenter.componentDidMount();
  }

  onClickSignOut() {
    this.presenter.onClickSignOut();
  }

  onClickMenu(e, item) {
    e.preventDefault();
    this.navigateTo(item.route);
  }

  render() {
    const user = this.getCurrentUser();
    const schemas = this.getSchemas();
    const roles = this.getCurrentRoles();
    if (user === undefined || schemas === undefined) {
      return <Progress />;
    }
    const settings = [
      {
        name: "Edit Account",
        route: "/account",
        icon: "bi bi-person-check",
      },
      // {
      //     name: "Notification",
      //     route: "/notification",
      //     icon: "bi bi-bell"
      // },
    ];
    const setting = {
      name: "Settings",
      icon: "bi bi-sliders",
      route: settings,
    };
    const menus = [
      {
        name: "Users",
        route: "/collection/users",
        icon: "bi bi-person",
      },
      {
        name: "Store",
        route: "/collection/storeAnalytics",
        icon: "bi bi-paperclip",
      },
      // {
      //   name: "roles",
      //   route: "/collection/roles",
      //   icon: "bi bi-paperclip",
      // },
    ];
    return (
      <Layout>
        <Layout.Context.Consumer>
          {(value) => (
            <OffCanvas onSetShow={value.setCollapse} show={value.collapsed}>
              <div
                className="offcanvas-body p-0"
                style={{ backgroundColor: "white" }}
              >
                <nav className="">
                  <div
                    className="text-center p-4"
                    style={{ backgroundColor: "#83c14a" }}
                  >
                    <LogoHolder
                      className="bg-white"
                      textClassName="text-dark"
                      logo={user.picture}
                      name={user.username}
                    />
                    <p className="text-white mt-3">
                      {user.name || user.username}
                    </p>
                  </div>
                  <hr className="dropdown-divider bg-light" />
                  <div className="m-3">
                    <Menu
                      onClickItem={this.onClickMenu.bind(this)}
                      menus={menus}
                    />
                  </div>
                </nav>
              </div>
              <div className="bg-white">
                <button
                  className="nav-link text-dark btn btn-link m-3"
                  onClick={this.onClickSignOut.bind(this)}
                >
                  <i className="bi bi-power"></i>
                  <span className="ms-2 fw-bold small">Log out</span>
                </button>
              </div>
            </OffCanvas>
          )}
        </Layout.Context.Consumer>
        <main className="vh-100 d-flex flex-column">
          <Routes>
            <Route
              exact
              path={"/collection/dashboard"}
              element={<DashboardPage />}
            />
            <Route
              exact
              path={"/collection/:name"}
              element={<CollectionListPage />}
            />
            <Route
              exact
              path={"/collection/:name"}
              element={<StoreTransactionPage />}
            />
            <Route path={"/collection/roles/form"} element={<RoleFormPage />} />
            <Route
              path={"/collection/rolesBi/form"}
              element={<RoleBiFormPage />}
            />
            <Route
              path={"/collection/rolesBi/form/:id"}
              element={<RoleBiFormPage />}
            />
            <Route
              path={"/collection/roles/form/:id"}
              element={<RoleFormPage />}
            />
            <Route
              path={"/collection/:name/form/"}
              element={<CollectionFormPage />}
            />
            <Route
              path={"/collection/:name/form/:id"}
              element={<CollectionFormPage />}
            />
            <Route path={"/migration"} element={<MigrationPage />} />
            <Route path={"/account"} element={<AccountPage />} />
            <Route element={<NotFoundPage />} />
          </Routes>
        </main>
      </Layout>
    );
  }
}

export default withRouter(MainPage);
