import React, { useEffect, useState, useRef } from "react";
import classNames from "../../classNames";

const noop = () => { };
const defaultProps = {
  loadOptions: noop,
  onChange: noop,
  onSearch: noop,
  onClickAdd: noop,
  onClickClear: noop,
  onFocus: noop,
  value: { label: "", value: "" },
  placeholder: "Select",
  dynamic: false,
};

function SelectSearch({
  className,
  value,
  placeholder,
  onChange,
  onSearch,
  onClickAdd,
  onClickClear,
  onFocus,
  focus,
  options,
  required,
  dynamic,
}) {
  const [search, setSearch] = useState("");
  const [text, setText] = useState(value.label);
  const [isOpen, setOpen] = useState(false);
  const ref = useRef(null);
  const [displayOptions, setDisplayOptions] = useState([]);
  const [previousValue, setPreviousValue] = useState(null);

  useEffect(() => {
    function clearLocalStorage() {
      localStorage.removeItem('selectedValues');
    }

    window.addEventListener('beforeunload', clearLocalStorage);
    return () => {
      window.removeEventListener('beforeunload', clearLocalStorage);
    };
  }, []);

  useEffect(() => {
    let selectedValues = JSON.parse(localStorage.getItem('selectedValues')) || [];
    if (value.label && !selectedValues.includes(value.label?.trim())) {
      selectedValues = selectedValues.filter(val => val !== previousValue);
      selectedValues.push(value.label?.trim());
      localStorage.setItem('selectedValues', JSON.stringify(selectedValues));
    }
    setPreviousValue(value.label?.trim());
    const uniqueOptions = getUniqueOptions(options, selectedValues);
    setDisplayOptions(uniqueOptions);
  }, [options, value.label]);

  function getUniqueOptions(options, selectedValues) {
    const selectedValuesSet = new Set(selectedValues.map(val => val?.trim()));
    const uniqueOptions = new Set();

    return options.filter(option => {
      const label = option.label?.trim();
      if (!selectedValuesSet.has(label) && !uniqueOptions.has(label)) {
        uniqueOptions.add(label);
        return true;
      }
      return false;
    });
  }


  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setOpen(false);
      onFocus(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setText(value.label);
    setSearch("");
  }, [value]);

  function _onChange(e) {
    setSearch(e.target.value);
    setText(e.target.value);
    onSearch(e.target.value);
  }

  function onClick(e) {
    setOpen(!isOpen || focus);
  }

  function onSelect(option) {
    onChange(option);
    setOpen(false);
    setText(option.label);
    setSearch("");

    let selectedValues = JSON.parse(localStorage.getItem('selectedValues')) || [];
    if (previousValue) {
      selectedValues = selectedValues.filter(val => val !== previousValue);
    }
    if (!selectedValues.includes(option.label.trim())) {
      selectedValues.push(option.label.trim());
      localStorage.setItem('selectedValues', JSON.stringify(selectedValues));
    }
    setPreviousValue(option.label.trim());
  }

  function _onFocus(e) {
    onFocus(true);
  }

  function onClickIcon(e) {
    if (text) {
      // Logic to remove the selected value from localStorage
      const selectedValues = JSON.parse(localStorage.getItem('selectedValues')) || [];
      const updatedValues = selectedValues.filter(val => val?.trim() !== text?.trim());
      localStorage.setItem('selectedValues', JSON.stringify(updatedValues));

      // Clear the text and update any related state
      setText('');
      setSearch('');
      setOpen(false);

      // Call onClickClear if there is additional clearing logic
      onClickClear(e);
    } else if (dynamic) {
      onClickAdd(e);
    } else {
      onClick(e);
    }
  }


  const style = { cursor: isOpen && focus ? "text" : "default" };
  const icon = text ? "bi bi-x" : dynamic ? "bi bi-plus" : "bi bi-chevron-down";

  return (
    <div ref={ref} style={{ position: "relative" }}>
      <div className="input-group">
        <input
          onClick={onClick}
          type="text"
          className={classNames(className, "form-control border-end-0 pe-0")}
          placeholder={placeholder}
          value={text}
          onChange={_onChange}
          onFocus={_onFocus}
          style={style}
          required={required}
        />
        <button onClick={onClickIcon} className="btn btn-link" type="button">
          <i className={icon}></i>
        </button>
      </div>

      {isOpen && (
        <ul
          className="list-group rounded-0 bg-white"
          style={{ position: "absolute", top: "100%", width: "100%", maxHeight: "200px", overflowY: "auto", zIndex: 1000 }}
        >
          {displayOptions.map((option, index) => (
            <li key={index} type="button" className="list-group-item list-group-item-action" onClick={() => onSelect(option)}>
              {option.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

SelectSearch.defaultProps = defaultProps;

export default SelectSearch;
